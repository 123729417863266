@use 'settings.scss' as *;

@use '_mq.scss' as mq with (
  $breakpoints: $breakpoints,
  //$show-breakpoints: $show-breakpoints
);

@import 'mixins';

@import '~normalize.css/normalize.css';

// Slick configs
$slick-font-path: "../fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';

%slick-arrow {
    font-family: "Poppins-Medium";
    z-index: 5;
    color: map-get($colors, text);
    font-size: 12px;
}

.slick-arrow {
    position: relative;
    bottom: -12px;

    &.slick-disabled {
        background: #F9EEEF;
        border: 1px solid rgba(48, 44, 72, 0.5);
        border-radius: 4px;
    }
}

.slick-prev {
    width: 24px;
    height: 24px;
    border: 1px solid map-get($colors, text);
    border-radius: 4px;
    
    z-index: 5;
    left: 0;

    &:before {
        @extend %slick-arrow;
    }
}

.slick-next {
    @extend .slick-prev;
    left: auto;
    right: 0;

    &:before {
        @extend %slick-arrow;
    }

}

.slick-items-count {
    margin: 0 20px;
}

/* Generate fonts  */
@include gen-fonts($fonts);

* {
    margin: 0;
    box-sizing: border-box;
}

html {
    font-style: normal;
    /* height: 100%; */
/*     overflow-x: hidden; */
    font-family: "Poppins-Light";
    font-weight: 300;

    @include mq.mq($until: 1250px){
        overflow-x: hidden;
    }
}

body {
   /*  overflow-x: hidden; */
    /* min-height: 100%; */
    color: map-get($colors, text);
    @include mq.mq($until: 1250px){
        overflow-x: hidden;
    }
}

.d-contents {
    display: contents !important;
}

.fullwidth {
    width: 100% !important;
}

a {
    line-height: 0;
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &.clear-link {
        &:hover {
            text-decoration: none;
        }
    }

    &.underlined-link {
        &:hover {
            text-decoration: underline;
        }
    }

    &.link-underlined {
        text-decoration: underline;
    }
}

.pc-only {
    @include mq.mq($until: 900px){
        display: none !important;
    }
}

.small-devices {
    @include mq.mq($from: 901px){
        display: none !important;
    }

    @include mq.mq($until: 900px){
        display: initial !important;
    }
}

.adaptive {
    @include adaptive-width;
}

.management {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ul-clear {
    list-style: none;
    padding: 0;
}

.btn {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &_default {
        color: map-get($colors, white);
        text-transform: capitalize;
        font-size: map-get($fontsize, default);
        line-height: 19px;
        background: map-get($colors, buttons, default);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 12px;
        width: 257px;
        height: 49px;
        transition: 0.3s;

        &:hover {
            background: map-get($colors, buttons, default_hover);
        }
    }

    &_white {
        border: 1px solid rgba(48, 44, 72, 0.8);
        border-radius: 4px;
        background: map-get($colors, white);
        color: map-get($colors, text);
        line-height: 19px;
        font-family: 'Rubik-Light';
        padding: 10px;
        height: 39px;
        margin: 35px auto;

        &:hover {
            background: map-get($colors, white);
        }
    }
}

%block {
    /* width: 1186px; */
    width: 1180px;
    @include adaptive-width;
    margin: 0 auto;

    @include mq.mq($until: 1250px){
        padding: 0 20px;
    }

    @include mq.mq($until: 1200px){
        width: 866px;
        padding: 0;
    }

    @include mq.mq($until: 1200px){
        width: 100%;
        padding: 0 18px;
    }
}

.wrapper {
    /* padding: 0 130px; */
    @extend %block;
}

.header {

    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend %block;
    padding: 35px 0 0;
    background-color: map-get($colors, white);

    @include mq.mq($until: 1250px){
        justify-content: space-between;
        padding: 30px 0;
    }

    .entrance {
        width: 91px;
        height: 34px;
        font-size: map-get($fontsize, extra_small);
    }

    &__nav {
        ul {
            margin: 0;

            li {
                display: inline-block;
                margin: 8px 12.5px;
                color: map-get($colors, text);
                font-size: map-get($fontsize, small);
                line-height: 130%;
                letter-spacing: 0.03em;
                font-weight: 300;

                a {
                    transition: 0.3s;

                    &:hover {
                        text-decoration-line: underline;
                        color: #F44336;
                    }
                }
            }
        }
    }
    
    .mob-menu {
        position: absolute;
        left: 0;
        top: 88px;
        z-index: 10;
        width: 100%;
        background: #F9EEEF;
        padding: 55px 0;
        display: none;
        height: 100%;

        .nav {
            li {
                margin-top: 15px;
                line-height: 130%;
                padding: 0 18px 15px;
                border-bottom: 1px solid #C7B6B8;
                font-family: 'Poppins-Regular';

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .entrance {
            width: calc(100% - 36px);
            margin: 27px auto 0;
            height: 48px;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Rubik-Regular';
        }

    }
}

.burger {
    width: 24px;
    cursor: pointer;
}

.welcome { 
    position: relative;

    h1 {
        font-size: 60px;
        line-height: 130%;
        letter-spacing: 0.03em;
        font-family: "Poppins-Light";
        font-weight: 300;
        width: 511px;
        position: relative;
        z-index: 2;

        @include mq.mq($until: 580px){
            font-size: 37.6583px;
            line-height: 130%;
            margin-top: 0;
        }

        @include mq.mq($until: 375px){
            font-size: 34px;
            line-height: 130%;
        }
    }

    .kbd {
        border-radius: 4px;
        position: relative;

        span {
            position: relative;
            z-index: 3;
        }

        &::before {
            content: " ";
            background: #F9EEEF;
            display: inline-block;
            position: absolute;
            top: 14px;
            width: 100%;
            height: 78%;
            z-index: 1;
            border-radius: 4px;
            padding: 0 10px;
            left: -10px;
        }
        
        &_fullheight {
            &::before {
                height: 65%;
                top: 17px;
                padding: 0 5px;
                left: -5px;
            }
        }

    }

    .map {
        position: absolute;
        right: 0;
        top: 0;
        width: 760px;
        @include adaptive-width;

        @include mq.mq($until: 1250px){
            right: -145px;
        }

        @include mq.mq($until: 580px){
            position: static;
            margin-top: 20px;
            margin-bottom: 49px;
        }
    }

    &__body {
        padding-top: 127px;

        @include mq.mq($until: 580px){
            padding-top: 39px;
        }
    }

    &__benefits {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0 -30px;
        width: 750px;
        @include adaptive-width;
        position: relative;
        z-index: 3;
        margin-bottom: 140px;

        @include mq.mq($until: 800px){
            margin: 0 -20px
        }

        @include mq.mq($until: 700px){
            justify-content: space-between;
            margin: 0;
        }

        @include mq.mq($until: 580px){
            margin-top: 10px;
        }

        .benefit {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 50px 30px;
            @include adaptive-width;
            text-align: left;

            @include mq.mq($until: 580px){
                flex-direction: row;
            }

            &__content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                @include mq.mq($until: 580px){
                    margin-left: 15px;
                }
            }
            

            @include mq.mq($until: 800px){
                margin: 50px 20px;
            }

            @include mq.mq($until: 700px){
                margin: 30px 20px;
            }

            @include mq.mq($until: 580px){
                margin: 20px 0 0;
            }

            &:nth-child(1){
                flex-basis: 184px;
            }

            &:nth-child(2){
                flex-basis: 171px;
            }
            
            &:nth-child(3){
                flex-basis: 214px;

                @include mq.mq($until: 800px){
                    flex-basis: 188px;
                }
            }

            @include mq.mq($until: 580px){
                flex-basis: 100% !important;
            }

            span {
                width: 100%;
            }

            &__title {
                font-size: map-get($fontsize, title);
                line-height: 130%;
                font-family: 'Poppins-Regular';
                letter-spacing: 0.03em;
                margin: 10px 0;

                @include mq.mq($until: 580px){
                    margin-top: 0;
                }
            }

            &__descr {
                font-family: 'Poppins-Light';
                font-size: map-get($fontsize, default);
                line-height: 160%;
                letter-spacing: 0.03em;
            }
        }
    }
}

.cards .title {
    margin-bottom: 0;
}

.title {
    text-align: center;
    font-size: 32px;
    line-height: 150%;
    letter-spacing: 0.03em;
    font-family: 'Poppins-Regular';
    width: 507px;
    @include adaptive-width;
    margin: 0 auto 40px;

    @include mq.mq($until: 580px){
        font-size: map-get($fontsize, title);
    }

    .badge {
        background: map-get($colors, buttons, default);
        border-radius: 4px;
        font-size: map-get($fontsize, extra_small);
        line-height: 14px;
        font-family: "Rubik-Medium";
        color: #fff;
        padding: 3px 13px;
        position: relative;
        bottom: 20px;
        right: 10px;
    }
}

.cards {

    margin-bottom: 81px;

    &__nav {
        position: relative;
        width: 130px;
        margin-left: auto;
        display: flex;
        top: -20px;
        align-items: center;
        justify-content: flex-end;

        @include mq.mq($until: 900px){
            top: 0;
        }
    }

    &__items {
/*         display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center; */
        /* margin: 0 -10px; */
        /* width: 100%; */
        margin: 0 auto;
        width: 100%;
    }

    .card {
        margin: 20px 10px 0;
        width: 380px;
        min-height: 508px;
        max-width: 100%;
        background: rgba(249, 238, 239, 0.1);
        border: 1px solid #F9EEEF;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        @include mq.mq($until: 1250px){
            width: 366px;
        }

        @include mq.mq($until: 1200px){
            width: 275px;
        }

        @include mq.mq($until: 576px){
            width: 100%;
            margin: 15px 0;
        }

        &__head {
            display: flex;
            justify-content: center;
            align-items: center;    
            width: 100%;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background: #F9EEEF;
            padding: 22px;

            @include mq.mq($until: 1250px){
                padding: 15px 20px;
            }

            &_column {
                flex-direction: column;

                .card__title {
                    text-align: center;
                    padding-top: 10px;
                    line-height: 160%;
                }
            }
        }

        &__body {
            padding: 0 66px;
            width: 100%;

            @include mq.mq($until: 1250px){
                padding: 0px 20px;
            }
        }

        &__title {
            text-transform: uppercase;
            font-size: map-get($fontsize, title);
            line-height: 36px;
            padding-left: 10px;
            font-family: 'Poppins-Regular';
        }

        .price {
            font-size: map-get($fontsize, title);
            line-height: 36px;
            font-family: 'Poppins-Regular';
            margin: 24px 0;
            text-align: center;

            &-title {
                text-align: center;
                margin-top: 15px;
                line-height: 24px;
                color: rgba(48, 44, 72, 0.8);
            }
        }

        &__label {
            color: map-get($colors, text);
            text-align: left;
            line-height: 24px;
            margin-top: 15px;
            margin-right: auto;
            font-family: 'Poppins-Light';

            span {
                font-family: 'Poppins-Regular';
            }
        }

        &__details {
            display: none;
            border-top: 1px solid #F9EEEF;
            width: 100%;
            padding: 10px 25px 30px;
        }

        .btn_default {
            width: 100%;
            margin-top: 25px;
            margin-bottom: 20px;
        }

        .btn_transparent {
            color: map-get($colors, text);
            font-family: 'Rubik-Light';
            margin: 0 auto 50px;
            display: block;
        }

    }

    &_detailed {
        margin-bottom: 90px;

        .card {
            padding: 40px 43px 20px;

            .detail-label {
                font-family: 'Rubik-Light';
                line-height: 19px;
                color: map-get($colors, text);

                &:first-of-type {
                    margin-top: 10px;
                }
            }

            .price, .detail {
                padding-bottom: 15px;
            }
        }

        br {
            display: block;
            content: "";
            margin: 5px 0;
        }
    }
 
    .need-install {
        .price {
            margin-top: 10px;
            margin-bottom: 15px;
        }
    }

    .border {
        background: rgba(249, 238, 239, 0.5);
        width: 100%;
        height: 1px;
        margin: 0 -66px;
        width: calc(100% + 132px);

        @include mq.mq($until: 1250px){
            margin: 0 -20px;
            width: calc(100% + 40px);
        }
    }
   
}

.hide {
    display: none;
}

.align-start {
    align-items: flex-start !important;
}

.description-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 120px;

    .description-image {
        @include mq.mq($until: 1024px){
            display: none;
        }
    }

    .description-descr {
        flex-grow: 1;
        margin-left: 70px;
        flex-basis: 420px;
        
        @include mq.mq($until: 1024px){
            margin-left: 0;
        }

        &__label {
            font-size: map-get($colors, small);
            line-height: 180%;
            letter-spacing: 0.03em;            
            margin-bottom: 20px;

            @include mq.mq($until: 1024px){
                text-align: center;
            }

            @include mq.mq($until: 580px){
                text-align: left;
            }
        }

        &__item {
            border-bottom: 1px solid rgba(48, 44, 72, 0.2);
            padding: 15px 0;
            display: flex;
            align-items: center;

            @include mq.mq($until: 475px){
                flex-wrap: wrap;
            }

            .link-underlined {
                font-size: map-get($fontsize, default);
                line-height: 24px;
            }

            .text {
                margin: 0 20px;
                font-family: 'Poppins-Regular';
                line-height: 140%;
                font-weight: 400;

                @include mq.mq($until: 475px){
                    flex-basis: 150px;
                    flex-grow: 1;
                }
            }

            .note {
                margin-left: auto;
                font-size: map-get($fontsize, title);
                line-height: 36px;
                font-family: 'Poppins-Regular';

                @include mq.mq($until: 475px){
                    flex-basis: 100%;
                    margin-left: 75px;
                }
            }
        }

        .group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            margin: 12px 20px 0;
            flex-basis: 100px;
            flex-grow: 1;

            .text {
                margin: 0;
                line-height: 160%;
                letter-spacing: 0.03em;
                font-family: 'Poppins-Light';
            }

            .note {
                margin: 0 0 10px;
            }
        }
    }
}

.about {

    @include mq.mq($until: 475px){
        .text {
            flex-basis: auto !important;
            padding-bottom: 20px;
        }
    }
   
}

.contacts {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    @include mq.mq($until: 1250px){
        justify-content: center;
    }

    @include mq.mq($until: 580px){
       flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__item {
        padding-left: 50px;

        @include mq.mq($until: 1250px){
            padding-left: 20px;
        }

        @include mq.mq($until: 580px){
            padding: 0 !important;
            text-align: center;
        }
        
        &:first-child {
            padding-right: 136px;
            padding-left: 0;

            @include mq.mq($until: 1250px){
                padding-right: 68px;
            }

            @include mq.mq($until: 890px){
                padding-right: 40px;
            }
        }

        &:nth-child(2){
            border-left: 1px solid rgba(48, 44, 72, 0.2);
            border-right: 1px solid rgba(48, 44, 72, 0.2);
            padding-right: 86px;

            @include mq.mq($until: 1250px){
                padding-right: 48px;

                .contacts__title {
                    max-width: 188px;
                }
            }

            @include mq.mq($until: 890px){
                padding-right: 20px;
            }

            @include mq.mq($until: 760px){
                border-right: none;
                margin-bottom: 30px;
            }

            @include mq.mq($until: 580px){
                border-left: none;

                .contacts__title {
                    max-width: 100%;
                }

                margin: 30px 0;
            }
        }

        &:nth-child(3){
            @include mq.mq($until: 1250px){
                padding-left: 32px;

                .contacts__title {
                    max-width: 175px;
                }
            }

            @include mq.mq($until: 890px){
                padding-left: 20px;
            }
        }

        .btn_default {
            margin-top: 25px;

            @include mq.mq($until: 1024px){
                width: 220px;
            }
        }
    }

    &__title {
        margin: 15px 0 20px;
        font-size: map-get($fontsize, title);
        line-height: 130%;
        letter-spacing: 0.03em;
        font-family: 'Poppins-Regular';
    }

    &__label {
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.03em;
        margin-bottom: 10px;

        &_bold {
            margin-top: 5px;
            font-family: 'Poppins-Medium';
            line-height: 120%;
        }
    }
}

.footer {
    margin-top: 120px;
    min-height: 261px;
    width: 100%;
    background: #471515;

    @include mq.mq($until: 580px){
        margin-top: 80px;
    }


    @include mq.mq($until: 1110px){
        .logo-footer {
            margin-bottom: 40px;
        }
    }

    &__content {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 80px 0 53px;

        @extend %block;

        @include mq.mq($until: 1250px){
            padding: 80px 20px 53px;
        }

        @include mq.mq($until: 1110px){
            flex-direction: column;
            align-items: center;
        }

        @include mq.mq($until: 835px){
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        @include mq.mq($until: 475px){
            padding: 40px 20px;
        }

        @include mq.mq($until: 335px){
            padding: 50px 18px;
        }
    }

    &__nav {
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        /* flex-basis: 650px; */
        flex-basis: 590px;

        @include mq.mq($until: 1110px){
            flex-basis: auto;
        }

        @include mq.mq($until: 835px){
            flex-basis: auto;
            justify-content: space-between;
            margin-top: 42px;
            max-width: 100%;
        }

        &-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            @include mq.mq($until: 835px){
                flex-basis: 43%;
                width: 43%;
                margin-bottom: 20px !important;
            }

            @include mq.mq($until: 475px){
                max-width: 100%;
                width: 153px;
                flex-grow: 1;
            }

            @include mq.mq($until: 360px){
                width: 143px;
            }

            @include mq.mq($until: 335px){
                width: 140px;
            }

            &:first-child {
                margin: 0 72px 0 90px;

                @include mq.mq($until: 1100px){
                    margin: 0;
                }

                @include mq.mq($until: 475px){
                    margin-right: 10px;
                }
            }

            &:nth-child(2){
                margin-right: 80px;

                @include mq.mq($until: 1110px){
                    margin-left: 103px;
                }

                @include mq.mq($until: 880px){
                    margin-left: 73px;
                }

                @include mq.mq($until: 835px){
                    margin: 0;
                }
            }

            &:nth-child(3){
                @include mq.mq($until: 475px){
                    margin-top: 20px;
                    margin-right: 10px;
                }
            }

            &:nth-child(4){
                margin-left: auto;

                @include mq.mq($until: 1110px){
                    margin-left: 60px;
                }

                @include mq.mq($until: 835px){
                    margin-left: 0;
                }
            }
        }

        &-title {
            line-height: 130%;
            color: rgba(255, 255, 255, 0.9);
            font-family: 'Poppins-Medium';
            letter-spacing: 0.03em;
            min-width: 116px;
            margin-bottom: 5px;
        }

        &-icon {
            width: 24px;
            margin-bottom: 8px;
        }

        .border {
            width: 100%;
            background: map-get($colors, white);
            height: 1px;
            margin-bottom: 10px;

            @include mq.mq($until: 475px){
                display: none;
            }
        }

        &-items {
            padding: 0;
            list-style: none;

            li {
                font-size: map-get($fontsize, small);
                line-height: 17px;
                color: rgba(255, 255, 255, 0.7);
                margin-top: 10px;
            }
        }
    }

    .payments {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -5px;
        width: 194px;
        max-width: 100%;

        @include mq.mq($until: 475px){
            margin: 10px -5px 0;
        }

        img {
            border-radius: 3.27273px;
            margin: 5px;

            @include mq.mq($until: 475px){
                margin: 2.5px;
            }
        }
    }

    .payment-icon-small {
        width: 36px;
    }

    .payment-icon-big {
        width: 82px;

        @include mq.mq($until: 475px){
            width: 77px;
        }
    }
}

.slick-items-count {
    @include mq.mq($until: 576px){
        display: none;
    }
}

.mob-hide {
    width: 100%;
    margin: 15px auto 25px;
    display: block;
}

.load-all {
    @extend .mob-hide;
}